import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { graphql, useStaticQuery } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import '../style/about.scss';
import Layout from '../layouts/plain';
import SEO from '../components/seo';
import Cross from '../images/cross.svg';
import useLanguageStrings from '../hooks/useLanguageStrings';

const query = graphql`
  {
    pageEN: contentfulPage(contentful_id: { eq: "1L7RWbMhvhAz9NooEe2OGA" }) {
      title
      lead
      body {
        json
      }
    }
    pageDA: contentfulPage(
      contentful_id: { eq: "1L7RWbMhvhAz9NooEe2OGA" }
      node_locale: { eq: "da" }
    ) {
      title
      lead
      body {
        json
      }
    }
  }
`;
const back = () => {
  /* eslint-env browser */
  if (typeof window !== 'undefined') window.history.back();
};

function About({ locale }) {
  const { pageEN, pageDA } = useStaticQuery(query);
  const { title, lead, body } = locale === 'da' ? pageDA : pageEN;
  const BodyComponent = useMemo(() => documentToReactComponents(body.json), []);
  const { learnMore } = useLanguageStrings();
  return (
    <>
      <SEO title={title} description={lead} />
      <Layout theme="theme-white" id="about">
        <header>
          <div className="upper-east">
            <button onClick={back} className="btn-" type="button">
              <Cross alt="Close" className="close" />
            </button>
          </div>
        </header>
        <div className="upper-west">
          <h1>{title}</h1>
        </div>
        <main className="font-blue">
          {BodyComponent}
          <div>
            <p>{learnMore}</p>
            <p>
              <a
                href="https://www.weareheadlight.com/"
                className="decoration-none font-blue"
              >
                www.weareheadlight.com
              </a>
            </p>
          </div>
        </main>
      </Layout>
    </>
  );
}

About.defaultProps = {
  locale: null,
};

About.propTypes = {
  locale: PropTypes.string,
};

export default About;
